import React from 'react';

const Handshake: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0161 12.9001L20.1517 15.0351C20.615 15.4989 20.8757 16.128 20.8757 16.7836C20.8757 17.4392 20.6154 18.068 20.1521 18.5318C19.6883 18.9956 19.059 19.2564 18.4031 19.2564C17.7473 19.2564 17.1183 18.9959 16.6545 18.5322L14.5197 16.398L15.9337 14.9836L18.0685 17.1178C18.1572 17.2065 18.2776 17.2564 18.4031 17.2564C18.5284 17.2564 18.6486 17.2067 18.7372 17.1182C18.8259 17.0295 18.8757 16.9091 18.8757 16.7836C18.8757 16.6583 18.826 16.538 18.7374 16.4493L16.6021 14.3145L18.0161 12.9001Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9619 15.0227L17.6114 16.6377C18.0748 17.1015 18.3355 17.7307 18.3355 18.3863C18.3355 19.0419 18.0752 19.6707 17.6118 20.1345C17.148 20.5983 16.5187 20.8591 15.8628 20.8591C15.207 20.8591 14.578 20.5986 14.1143 20.1349L12.6207 18.6376L14.0348 17.2232L15.5283 18.7205C15.617 18.8092 15.7374 18.8591 15.8628 18.8591C15.9881 18.8591 16.1083 18.8094 16.197 18.7209C16.2857 18.6321 16.3355 18.5118 16.3355 18.3863C16.3355 18.2609 16.2857 18.1407 16.1972 18.0519L14.5479 16.4371L15.9619 15.0227Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8656 17.0524L15.2424 18.4301C15.2425 18.4301 15.2424 18.43 15.2424 18.4301C15.7041 18.8919 15.9636 19.5182 15.9636 20.1712C15.9636 20.824 15.7043 21.4502 15.2429 21.9119C15.014 22.1412 14.742 22.3231 14.4426 22.4469C14.143 22.5708 13.8219 22.6342 13.4977 22.6335L12.4977 22.6313L12.5021 20.6313L13.5021 20.6335C13.5625 20.6337 13.6223 20.6218 13.6782 20.5987C13.734 20.5757 13.7847 20.5418 13.8273 20.499L14.5353 21.2053L13.8281 20.4982C13.9148 20.4115 13.9636 20.2939 13.9636 20.1712C13.9636 20.0486 13.9148 19.9309 13.8281 19.8442L12.4509 18.4662L13.8656 17.0524Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.37491 0.460938L7.91412 2.00015H10.3749V4.00015H7.08569L6.37491 3.28936L2.53912 7.12515L3.62491 8.21094V11.8752H1.62491V9.03936L-0.289307 7.12515L6.37491 0.460938Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.0638 16.2311C4.879 16.4159 4.87911 16.7153 5.06367 16.8999C5.24823 17.0844 5.54765 17.0845 5.73246 16.8997L6.11854 16.5137C6.30334 16.3289 6.30323 16.0294 6.11867 15.8449C5.93411 15.6603 5.63468 15.6602 5.44988 15.845L5.0638 16.2311ZM3.64945 18.3141C2.68368 17.3483 2.68391 15.7826 3.64959 14.8169L4.03567 14.4308C5.00135 13.4651 6.56711 13.4649 7.53289 14.4307C8.49866 15.3964 8.49843 16.9622 7.53275 17.9279L7.14667 18.314C6.18099 19.2796 4.61523 19.2799 3.64945 18.3141Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63819 19.9606C9.45757 20.1412 9.45757 20.434 9.63819 20.6147C9.81881 20.7953 10.1116 20.7953 10.2923 20.6147L10.4895 20.4174C10.6702 20.2368 10.6702 19.9439 10.4895 19.7633C10.3089 19.5827 10.0161 19.5827 9.83547 19.7633L9.63819 19.9606ZM8.22398 22.0289C7.26231 21.0672 7.26231 19.508 8.22398 18.5464L8.42126 18.3491C9.38293 17.3874 10.9421 17.3874 11.9038 18.3491C12.8654 19.3108 12.8654 20.8699 11.9038 21.8316L11.7065 22.0289C10.7448 22.9905 9.18565 22.9905 8.22398 22.0289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.02123 13.8286C3.94034 13.7513 3.83222 13.709 3.72035 13.7109C3.60848 13.7128 3.50187 13.7587 3.42366 13.8388L3.20766 14.0596C3.13308 14.1369 3.09054 14.2397 3.08864 14.3471C3.08673 14.455 3.12594 14.5596 3.19831 14.6396C3.23677 14.6822 3.28353 14.7165 3.33565 14.7404C3.38777 14.7644 3.44422 14.7774 3.50154 14.7789C3.55887 14.7803 3.61589 14.77 3.66913 14.7487C3.72236 14.7274 3.77071 14.6955 3.81122 14.6549L4.02908 14.437C4.06911 14.3969 4.10073 14.3493 4.12207 14.2968C4.14342 14.2444 4.15407 14.1882 4.15339 14.1315C4.15271 14.0749 4.14072 14.019 4.11813 13.9671C4.09553 13.9151 4.0622 13.8677 4.02123 13.8286ZM5.40277 12.3824C4.94126 11.9415 4.3245 11.7003 3.68629 11.7112C3.04806 11.7221 2.43986 11.9841 1.99362 12.4405L1.77612 12.6629L1.77344 12.6657C1.34457 13.1075 1.09986 13.696 1.08895 14.3117C1.07805 14.9273 1.30175 15.5241 1.71467 15.9808M5.40277 12.3824C5.63629 12.6054 5.82323 12.873 5.95205 13.1691C6.08098 13.4654 6.14938 13.7845 6.15325 14.1076C6.15711 14.4307 6.09636 14.7514 5.97455 15.0507C5.85275 15.35 5.67235 15.6219 5.44396 15.8505L5.22683 16.0677C4.99475 16.3001 4.71743 16.4833 4.41251 16.6054C4.10741 16.7276 3.78065 16.7864 3.4521 16.7782C3.12356 16.7701 2.8001 16.6952 2.50141 16.5582C2.20275 16.4211 1.93503 16.2246 1.71467 15.9808"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43223 18.03C7.25162 18.2106 7.25162 18.5035 7.43224 18.6841C7.61285 18.8647 7.90569 18.8647 8.08631 18.6841L8.19556 18.5748C8.37617 18.3942 8.37617 18.1014 8.19556 17.9208C8.01494 17.7402 7.7221 17.7402 7.54148 17.9208L7.43223 18.03ZM6.01802 20.0983C5.05636 19.1366 5.05635 17.5775 6.01802 16.6158L6.12727 16.5066C7.08893 15.5449 8.6481 15.5449 9.60977 16.5066C10.5714 17.4682 10.5714 19.0274 9.60977 19.9891L9.50052 20.0983C8.53886 21.06 6.97969 21.06 6.01802 20.0983Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.02 7.74399L21.7864 12.642C22.2153 13.0839 22.46 13.6724 22.4709 14.2881C22.4818 14.9037 22.2581 15.5005 21.8452 15.9573L21.8441 15.9584C21.6236 16.2016 21.356 16.3975 21.0575 16.5341C20.759 16.6708 20.4359 16.7455 20.1078 16.7536C19.7796 16.7617 19.4532 16.7031 19.1483 16.5814C18.8435 16.4596 18.5665 16.2772 18.3342 16.0452L19.7475 14.6301C19.7882 14.6707 19.8367 14.7027 19.8902 14.724C19.9436 14.7454 20.0008 14.7556 20.0583 14.7542C20.1158 14.7528 20.1725 14.7397 20.2248 14.7157C20.2768 14.6919 20.3236 14.6578 20.3621 14.6154C20.4341 14.5354 20.4731 14.4311 20.4712 14.3235C20.4693 14.2157 20.4266 14.1127 20.3516 14.0353L15.5867 9.13881L17.02 7.74399Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6248 0.460938L24.289 7.12515L22.3748 9.03936V11.8752H20.3748V8.21094L21.4606 7.12515L17.6248 3.28936L16.914 4.00015H14.0424C13.5794 4.00012 13.1209 4.09131 12.6931 4.2685C12.2682 4.44453 11.8818 4.70202 11.5558 5.02642L8.10443 8.6146C8.05666 8.66339 8.02871 8.72817 8.02608 8.7964C8.02346 8.86463 8.0463 8.93139 8.09018 8.98371C8.13406 9.03602 8.19583 9.07014 8.26348 9.07943C8.33113 9.08871 8.39981 9.0725 8.45616 9.03395L8.46746 9.02622L12.8769 6.18348L13.4458 6.82859C13.6682 7.08072 13.9422 7.28198 14.2493 7.4187C14.5564 7.55542 14.8894 7.62438 15.2255 7.62089C15.5616 7.6174 15.8931 7.54154 16.1973 7.39847C16.5015 7.25541 16.7713 7.04849 16.9884 6.79181L17.6341 6.02823L19.1612 7.31966L18.5155 8.08324C18.113 8.55927 17.6126 8.94299 17.0485 9.20831C16.4843 9.47363 15.8697 9.61431 15.2463 9.62078C14.6229 9.62725 14.0054 9.49936 13.4359 9.24582C13.1352 9.11197 12.8516 8.94474 12.5902 8.7479L9.57251 10.6934C9.10877 11.006 8.54592 11.137 7.99148 11.0608C7.43187 10.984 6.92084 10.7018 6.55784 10.269C6.19484 9.8362 6.00582 9.28386 6.02757 8.71941C6.04924 8.15668 6.27886 7.62205 6.67189 7.2189L10.1282 3.62557L10.135 3.61872C10.6481 3.10554 11.2573 2.69847 11.9278 2.42075C12.5982 2.14304 13.3168 2.00012 14.0424 2.00015C14.0425 2.00015 14.0424 2.00015 14.0424 2.00015H16.0856L17.6248 0.460938Z"
      />
    </svg>
  );
};

export default Handshake;
